import { Vue, Component } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { BButton, BForm, BFormInput, BFormGroup, BCard, BLink } from "bootstrap-vue";
import { BCardTitle, BCardText, BFormCheckbox } from "bootstrap-vue";
import { BInputGroup, BInputGroupPrepend, BInputGroupAppend } from "bootstrap-vue";
import { IAdminAuthLoginDto } from "@lib";
import AltLogo from "@/core/components/alt-logo";

@Component({
    components: {
        BCard,
        BCardTitle,
        BLink,
        BCardText,
        BButton,
        BFormCheckbox,
        BForm,
        BFormGroup,
        BFormInput,
        BInputGroup,
        BInputGroupPrepend,
        BInputGroupAppend,
        ValidationProvider,
        ValidationObserver,
        AltLogo,
    },
})
export default class AdminLogin extends Vue {
    private resources = {
        LabelTitleText: "ВХОД",
        LabelSubtitle1Text: "Приветствуем!",
        LabelSubtitle2Text: "Пожалуйста, войдите в аккаунт, чтобы продолжить:",
        TextBoxEmailLabel: "Почта",
        TextBoxPasswordLabel: "Пароль",
        ButtonText: "Вход",
        ValidatorId: "adminLoginForm",
    };

    private email = "";
    private password = "";
    private passwordVisible = false;

    private togglePasswordVisibility(): void {
        this.passwordVisible = !this.passwordVisible;
    }

    private async login(): Promise<void> {
        try {
            const valid = await (this.$refs[this.resources.ValidatorId] as any).validate();
            if (!valid) {
                return;
            }

            this.$alt.loader.show();

            const dto: IAdminAuthLoginDto = {
                email: this.email,
                password: this.password,
            };

            const service = this.$alt.system.usecase.createAdminAuthUseCase();
            const result = await service.login(dto);
            this.$alt.system.token.cleanData();
            this.$alt.system.token.setAdminToken(result.accessToken);
            await this.$router.push({ name: "analytics" });
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }
}

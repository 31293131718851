





import { Vue, Component, Prop } from "vue-property-decorator";
import AltLogoFull from "./alt-logo-full.vue";
import AltLogoMin from "./alt-logo-min.vue";

@Component({
    name: "alt-logo",
    components: {
        AltLogoFull,
        AltLogoMin,
    },
})
export default class AltLogo extends Vue {
    @Prop({ type: Boolean, default: false })
    private full!: boolean;

    @Prop({ type: String, default: "fil2" })
    private classSubTitle!: string;
}


















































import { Vue, Component, Prop } from "vue-property-decorator";

@Component({
    components: {},
})
export default class AltLogoFull extends Vue {
    @Prop({ type: String, default: "fil2" })
    private classSubTitle!: string;
}
